<template lang="pug">
  header.header
    .header__container
      a.header__logo.logo(v-scroll-to="'#steps1'", @click="closeMenu")
        v-img(:src="Logo", alt="logo")
      .header__menu.menu
        button.menu__icon.icon-menu(type="button", @click="openMenu")
          span
        nav.menu__body
          ul.menu__list
            li.menu__item(:class="{ active: activeSection === 'steps1' }")
              a.menu__link(v-scroll-to="'#steps1'", @click="closeMenu()")
                | Головна
            li.menu__item(:class="{ active: activeSection === 'steps2' }")
              a.menu__link(v-scroll-to="'#steps2'", @click="closeMenu()")
                | Про Drivelink
            li.menu__item(:class="{ active: activeSection === 'steps3' }")
              a.menu__link(v-scroll-to="'#steps3'", @click="closeMenu()")
                | Характеристики
            li.menu__item(:class="{ active: activeSection === 'steps4' }")
              a.menu__link(v-scroll-to="'#steps4'", @click="closeMenu()")
                | Ціни
            li.menu__item(:class="{ active: activeSection === 'steps5' }")
              a.menu__link(v-scroll-to="'#steps5'", @click="closeMenu()")
                | Контакти
</template>

<script>
import Logo from '@/assets/images/logo.svg'

export default {
  name: 'HeaderBlock',
  data () {
    return {
      isScrolled: false,
      activeSection: 'steps1',
      Logo
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    openMenu () {
      document.body.classList.toggle('lock')
      document.querySelector('.menu__icon').classList.toggle('menu-open')
      document.querySelector('.menu__body').classList.toggle('menu-open')
    },
    closeMenu () {
      document.body.classList.remove('lock')
      document.querySelector('.menu__icon').classList.remove('menu-open')
      document.querySelector('.menu__body').classList.remove('menu-open')
    },
    handleScroll () {
      const sections = document.querySelectorAll('section')
      let currentSection = ''
      sections.forEach(section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight
        if (window.pageYOffset >= sectionTop - (sectionHeight / 7)) {
          currentSection = section.getAttribute('id')
        }
      })
      if (currentSection !== this.activeSection) {
        this.activeSection = currentSection
      }
      const header = document.querySelector('.header')
      if (window.pageYOffset > 50) {
        header.classList.add('scrolled')
      } else {
        header.classList.remove('scrolled')
      }
    }
  }
}
</script>
